import createSelector from 'lib/web/createSelector'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import config from 'constants/config'

export const isLuxPlusEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (optimizelyFeatureFlags): boolean => {
    return !!(config.LUXURY_PLUS_ENABLED && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusFeatureToggle])
  },
)

/**
 * Check if the user has LuxPlus+ membership in the current region.
 * Members are only members in the region they bought the subscription in
 */
const isEnabledInCurrentRegionLuxPlusMember = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.loyalty.member.subscription.item,
  (state: App.State) => state.geo.currentRegionCode,
  (isLuxPlusEnabled, memberSubscriptionItem, currentRegion): boolean => {
    return isLuxPlusEnabled && currentRegion === memberSubscriptionItem?.region
  },
)

/**
 * Check if the user is a LuxPlus+ member either subscribed e.g. 'base' tier. awaiting renewal or in free preview.
 * This is used to determine if the user can view but not redeem the benefits of LuxPlus+.
 */
export const checkCanViewLuxPlusBenefits = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.loyalty.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && (memberSubscriptionItem?.status === 'ACTIVE' || memberSubscriptionItem?.status === 'AWAITING_RENEWAL')
  },
)

export const isPendingRenewalLuxPlusMember = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.loyalty.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.status === 'AWAITING_RENEWAL'
  },
)

export const isFreePreviewLuxPlusMember = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.loyalty.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.tier === 'free_preview'
  },
)

/** Requirement to make any LuxPlus+ purchases */
export const checkCanRedeemLuxPlusBenefits = createSelector(
  checkCanViewLuxPlusBenefits,
  isFreePreviewLuxPlusMember,
  isPendingRenewalLuxPlusMember,
  (checkCanViewLuxPlusBenefits, isFreePreviewLuxPlusMember, isPendingRenewalLuxPlusMember): boolean => {
    return checkCanViewLuxPlusBenefits && !isFreePreviewLuxPlusMember && !isPendingRenewalLuxPlusMember
  },
)

export const isEligibleLuxPlusFreePreview = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.loyalty.products.freePreview?.offer?.isEligible,
  (isLuxPlusEnabled, luxPlusFreePreviewAvailable): boolean => {
    return isLuxPlusEnabled && !!luxPlusFreePreviewAvailable
  },
)

export const isEnabledLuxPlusDiscountedInsurance = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (isLuxPlusEnabled, optimizelyFeatureFlags): boolean => {
    return isLuxPlusEnabled && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusDiscountedInsuranceBenefit]
  },
)

export const selectHasLuxPlusFreePreviewBanner = createSelector(
  isLuxPlusEnabled,
  checkCanViewLuxPlusBenefits,
  (state: App.State) => state.config.luxPlusFreePreviewBannerDismissed,
  (
    isLuxPlusEnabled,
    canViewLuxPlusBenefits,
    luxPlusFreePreviewBannerDismissed,
  ): boolean => {
    return isLuxPlusEnabled && !canViewLuxPlusBenefits && !luxPlusFreePreviewBannerDismissed
  },
)

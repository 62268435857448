import {
  UPDATE_APPLE_PAY_AVAILABLE,
  UPDATE_GOOGLE_PAY_AVAILABLE,
  API_CALL,
  TOGGLE_SHOW_MARGIN_IN_SPOOF_MODE,
  DISMISS_LUX_PLUS_FREE_PREVIEW_BANNER,
} from './actionConstants'
import { AppDispatch } from '../store'
import { FETCH_MERCHANT_FEE_CONFIG, FETCH_PAYTO_SUPPORTED_BANKS, FETCH_DEPOSIT_CONFIG } from './apiActionConstants'
import { getMerchantFeeConfig, getPayToSupportedBanks, getDepositConfig } from 'api/payment'
import { setDismissed as cookieDismiss } from 'luxPlus/cookies/freePreviewBanner'

export function updateCanPayWithApplePay(flag: boolean) {
  return {
    type: UPDATE_APPLE_PAY_AVAILABLE,
    value: flag,
  }
}

export function updateCanPayWithGooglePay(flag: boolean) {
  return {
    type: UPDATE_GOOGLE_PAY_AVAILABLE,
    value: flag,
  }
}

export function fetchMerchantFeeConfig() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.merchantFeeConfigs.length !== 0) return

    dispatch({
      type: API_CALL,
      api: FETCH_MERCHANT_FEE_CONFIG,
      request: () => getMerchantFeeConfig(),
    })
  }
}

export function fetchPayToSupportedBanks() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.payToSupportedBanks.length !== 0) return

    dispatch({
      type: API_CALL,
      api: FETCH_PAYTO_SUPPORTED_BANKS,
      request: () => getPayToSupportedBanks(),
    })
  }
}

export function fetchDepositConfig() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.depositConfigs) return

    dispatch({
      type: API_CALL,
      api: FETCH_DEPOSIT_CONFIG,
      request: () => getDepositConfig(),
    })
  }
}

export function toggleShowMarginInSpoofMode() {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    const showMarginInSpoofMode = state.config.showMarginInSpoofMode
    dispatch({
      type: TOGGLE_SHOW_MARGIN_IN_SPOOF_MODE,
      showMarginInSpoofMode: !showMarginInSpoofMode,
    })
  }
}

export function dismissFreePreviewBanner() {
  cookieDismiss()
  return {
    type: DISMISS_LUX_PLUS_FREE_PREVIEW_BANNER,
  }
}
